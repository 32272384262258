import React from 'react'
import { POKER, POKER_LEARN } from '../../components/internal-links'
import Layout from '../../components/layout'
import Blogs from '../../components/Blogs/Blogs'
import SEO from '../../components/seo'
import { POKER_LEARN_PAGES } from '../../poker-tags'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { generateBreadcrumbsSchema } from '../../lib/generate-schema'
import { POKER_LEARN as POKER_DOWNLOAD_LINK } from '../../components/download-links'
import { pokerOneLinksPokerPage } from '../../components/one-links'
import {
  HOMEPAGE_BREADCRUMB_TITLE,
  POKER_BREADCRUMB_TITLE,
} from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: POKER_BREADCRUMB_TITLE,
    url: POKER,
  },
  {
    title: 'Learn Poker',
    url: POKER_LEARN,
  },
]

const LearnPoker: React.FC = () => {
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={POKER_DOWNLOAD_LINK}
      showPokerForm
      pokerOneLink={pokerOneLinksPokerPage}
    >
      <SEO
        title="Poker: A simple yet complete guide to understanding Poker | Mega"
        description="Learn Poker from the articles listed. Get to the history, gameplay, best websites, and much more from the bunch of articles. Click on the link &amp; read."
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <Blogs
        blogType="poker"
        tags={POKER_LEARN_PAGES}
        prefix={`${POKER}${process.env.GATSBY_CMS_LEARN_POKER_PREFIX}` || '/'}
        title={
          <>
            Learn Poker - <span className="underline">Guide</span>
          </>
        }
      />
    </Layout>
  )
}

export default LearnPoker
